
import * as bootstrap from 'bootstrap'
import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";
import ErpStockProductList from "./relations/product";
import ErpStockWarehouseLocationList from "./relations/warehouse_location";

export default class ErpStockOverviewList extends DefaultController {
    async init() {
        await super.init();
        this.childs = {
            products: new ErpStockProductList(this),
            warehouse_locations: new ErpStockWarehouseLocationList(this)
        }
    }

    protected getEntityData(elem: any) {
        return {}
    }
    bindListeners() {

    }
}